<template>
  <v-container fluid class="px-8 sw-pt-10">
    <transition name="fade">
      <div v-if="isDataLoaded">
        <v-card flat max-width="800" class="mx-auto">
          <div class="sw-page-title">
            {{ navigation.name }}
          </div>
          <div v-if="navigation.description">
            <div class="sw-page-subtitle">{{ navigation.description }}</div>
          </div>
          <div v-if="!messages || !messages.length" class="sw-text">
            {{ $vuetify.lang.t("$vuetify.emptyMessageListText") }}
          </div>
        </v-card>
        <v-card flat max-width="620" class="mx-auto">
          <v-row v-if="messages && messages.length">
            <v-col
              v-for="(message, i) in messages"
              :key="`message-${i}`"
              cols="12"
            >
              <v-hover>
                <template v-slot="{ hover }">
                  <v-card
                    link
                    :color="isMessageRead(message) ? 'grey lighten-3' : ''"
                    class="rounded-lg"
                    :elevation="hover ? 6 : 2"
                    :style="{ transition: 'all 0.3s' }"
                    @click.stop="getMessage(message.id)"
                  >
                    <v-card-text class="pa-6">
                      <v-row class="no-gutters align-center">
                        <v-col cols="6">
                          <span class="mb-2 text-h4 font-weight-black">{{
                            message.subject
                          }}</span>
                        </v-col>
                        <v-col cols="6" class="text-right">
                          <span>{{ transformDate(message.created_at) }}</span>
                        </v-col>
                      </v-row>
                      <div v-html="getDescription(message.body)"></div>
                    </v-card-text>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </transition>
    <div v-if="totalPages > 1" class="text-center">
      <v-pagination
        v-model="page"
        :length="totalPages"
        :total-visible="5"
        @input="listMessages"
        :color="gmSecondaryColor"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    isDataLoaded: false,
    messages: [],
    totalPages: null,
    page: 1,
    perPage: 40,
  }),
  computed: {
    userId() {
      return this.$store?.getters?.user?.id;
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
    navigation() {
      const navigation = this.$store?.getters?.navigation?.destinations?.find(
        (el) => el.component === "MessageListPage",
      );
      return navigation || {};
    },
  },
  methods: {
    listMessages() {
      if (!this.groupId) return;

      this.$http
        .get(`/groups/${this.groupId}/users/${this.userId}/messages`, {
          params: {
            type: "push",
            sort: "id:desc",
            lang: this.appLanguage,
            per_page: this.perPage,
            page: this.page,
          },
        })
        .then(
          (response) => {
            this.isDataLoaded = true;

            if (!response?.data?.data) return;

            this.messages = response.data.data;
            this.totalPages = response.data.pagination?.total_pages;
          },
          () => {
            this.isDataLoaded = true;
          },
        );
    },
    transformDate(value) {
      return moment(value).format("DD-MM-YYYY - HH.MM");
    },
    getDescription(value) {
      const div = document.createElement("div");

      div.innerHTML = value;

      const text = div.textContent || div.innerHTML || "";

      return text.length > 120 ? `${text.substring(0, 120)}...` : text;
    },
    isMessageRead(model) {
      return model.last_read ? true : false;
    },
    getMessage(id) {
      this.$router
        .push({
          name: "MessagePage",
          params: {
            message_id: id,
          },
        })
        .catch(() => {});
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.listMessages();
      },
    },
    appLanguage: {
      handler() {
        this.listMessages();
      },
    },
  },
};
</script>

<style scoped lang="scss"></style>
